.Bookingpp-div{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    overflow: hidden;
    z-index: 5;
    transition: all 1s ease
}
.Bookingpp-div.isActive{
    height: 100%;
    z-index: 5;
    transition: all 2s ease
}
.Bookingpp-div--booked{
    height: fit-content !important;
    overflow: visible  ;
}
.Bookingpp-div--background{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    z-index: 2;
}
.Bookingpp-div--background.isActive{
    height: 100%;
    z-index: 2;
}

.Bookingpp-div--main{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    gap: 0.5em;
    z-index: 5;
}
.Bookingpp-div--logo{
    position: relative;
    left: calc(50vw - 2.5em);
    top: 1.5em;
    border-radius: 50%;
    background-color:#FCFAFA;
    width: fit-content;
    /* width: 5em;
    height: 5em; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
    
}
.Bookingpp-img{
    width: 3em;
}
.Booking-div--logoBack{
    width: 4.5em;
    height: 4.5em;
    background-color: #31B8B8;
    border-radius: 50%;
    margin: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Bookingpp-div--info{
    display: flex;
    flex-direction: column;
    width:100%;
    background-color: #FCFAFA;
    padding: 1em;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: .4em;
    padding-top: 2.4em ;
    font-size: 1rem;
    border-radius: 2.4em 2.4em 0 0 ;
}

.Bookingpp-div--prop{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.1em;
}
.Bookingpp-h5--scotterID{
    font-size: 0.7rem;
    position: absolute;
    bottom: -0.8em;
    border-radius: 1.5em;
    padding: 0.3em 0.5em;
    background-color: #FCFAFA;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
}
.Bookingpp-div--battery{
    display: flex;
    background-color:rgba(0, 0, 0, 0.06);
    padding: 0.3em;
    gap: 4px;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 0.6em;
}
.Bookingpp-icon{
    font-size: 1.4rem;
    width: fit-content;
}
.Bookingpp-icon--bat {
    font-size: 1.1rem;
    width: fit-content;
}
.Bookingpp-h5--battery{
    width: fit-content;
}
.Bookingpp-div--direction {
    display: flex;
    padding: 0.3em;
    gap: 1em;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 0.6em;
}
.Bookingpp-div--footbtn{
    width: 100%;
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em
}
.Bookingpp-p--footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.7rem;
    color: #827979;
    text-align: center;
    font-weight: 400;
}
@media screen and (min-width: 850px) {
.Bookingpp-div--info{
    align-items: center;
}}