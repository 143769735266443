.trip-list{
    position: absolute;
    top:0;
    width: 100vw;
    height: 100vh;
    width: 100svw;
    height: 100dvh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: #F6F6F6;
    flex-direction: column;
    z-index: 9;
}

.title-trip_id{
    width: 350px;
    border-color: rgb(255, 255, 255, 0.5);
    box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    margin-top: 20px;
    /* margin-left: 30px;
    margin-right: 30px; */
    border-radius: 10px;
    align-items: baseline;
}

.trips-title{
    margin-top: 25px;
    font-size: larger;
    color: #F4BC68;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.details-title{
    margin-right: 50px;
}


.title-trip_id{
    font-size: medium;
    padding: 30px
}

.trip{
    margin-bottom: 15px;
    font-weight: bolder;
}


.click:after {
    content:"En pantalla grande";
}
@media (max-width: 1024px) {
    .click:after {
        content:"Tablet";
    }
}
@media (max-width: 480px) {
    .click:after {
        content:"Movil";
    }
}   