/* .Trip-div{
    position: absolute;
    top:-50%;
    left: 10%;
    right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 2em;
    margin: auto;
    background-color: azure;
    gap:1em;
    border-radius: 50%;
    border: 1px black solid;
    box-shadow: 0 0 5px black   ;
} */

/* .Trip-div--in {
    animation: puff-in-hor 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}
.Trip-div.Trip-div--out{
    animation: puff-out-hor 0.9s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
}
.Trip-div--main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
} */
/* .Trip-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}
.Trip-input{
    width: 45% ;
    font-size: 1.5rem;
    font-weight: 800;
    border-radius: 5px;
    border-style: 1px solid black;
    padding: 0.3em;
} */
.Trip-p{
    font-size: 1rem;
    font-weight: 500;
}
/* .Trip-button{
    font-size: 1rem;
    padding: 0.5em;
    border-radius: 5px;
    border-style: 1px solid black;
    color: black;
    background-color: rgba(238, 184, 83, 0.8);
} */



/* @keyframes puff-in-hor {
  0% {
    -webkit-transform: scaleX(2);
            transform: scaleX(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-out-hor {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(2);
        transform: scaleX(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }
} */

.Trip-btn{
    display: flex;
    gap:0.5em;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    padding: 0.65em 1.25em;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
    color: #FCFAFA;
    border-radius: 19px;
    min-width: 12em;
}   
.Trip-btn--NoBooking{
    background-color: #31b8b88b;
}
.Trip-btn--Booking{
    background-color: #31B8B8;
}
.Trip-btn--icon{
    font-size: 1.3em;
    width: fit-content;
}