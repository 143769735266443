/* .SpinRotate-div{
    aspect-ratio: 1;
    height: min(2.5em, 30px);
    background-color: rgba(240, 248, 255, 0);
    border-radius: 50%;
    border-top: 0.2em solid #4C545B;
    border-left: 0.2em solid #4C545B;
    border-bottom: 0.2em solid #4C545B;
    animation: rotation 2s infinite linear;
    padding: 0.2em;
    margin: 0.5em;
}


@keyframes rotation{
    0% {
        transform: rotate(0);
    }
    50%{
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg)
    }
    100%{ 
        transform: rotate(360deg)
    }
} */
.SpinRotate-div{
    aspect-ratio: 1;
    height: min(1em, 32px);
    border-radius: 50%;
    animation: load-pulse 0.85s infinite linear;
}

@keyframes load-pulse {
  0% {
    transform: scale(0.15);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
 