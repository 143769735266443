.ErrorMessage-background{
    position: absolute;
    top:0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #14120433;
    z-index: 99;

}
.ErrorMessage-div{
    display: flex;
    max-width: 80%;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    justify-content: center;
    height: fit-content;
    border-radius: 1.4em;
    background-color: #393939;
    z-index: 1;
}
.ErrorMessage-icon{
    font-size: 1.8em;
}
.ErrorMessage-button {
    color:#FCFAFA;
    border-radius: 1.2em;
    font-size: 0.8rem;
    box-shadow: 0em 0.2em 0.2em rgba(0, 0, 0, 0.25);
    background-color: #393939;
    padding: 0.7em 2em;
}

@media (hover:hover) {
    .ErrorMessage-button:hover {
        background-color: #252424;
    }
}

.ErrorMessage-button:active {
    background-color: #1c1c1c;
    box-shadow: 0 0 0.8em inset black;
}
.ErrorMessage-h3{
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5em;
    width: 7em;
    text-align: center;
}
.ErrorMessage-div-body{
    width: 100%;
    background-color: #FCFAFA;
    border-radius: 0 0  1.4em 1.4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 1.4em;
    gap: 0.5em;
}
.ErrorMessage-p{
    text-align:start;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 500;
    margin-left: 0.5em;
    color: #393939;
    margin-bottom: 0.5em;
    hyphens: auto;
    hyphenate-character: "-";
    
}
.ErrorMessage-div-head{
    background-color: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    gap: 1em;
    border-radius: 1.4em 1.4em 0 0;
    flex-direction: column;
    color: #FCFAFA;
}