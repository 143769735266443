.Landing-div {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    width: 100svw;
    height: 100dvh;
    background-color: #FCFAFA;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Landing-div--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
    gap: 1.5em;
    margin-top: 3em;
}

.Landing-img2 {
    position: absolute;
    bottom: 20%;
    /* right: 38vw; */
    width: 15.5em;
    z-index: 1;
}

.Landing-div--foot {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 5em;
}

.Landing-svg {
    position: relative;
    color: #31B8B8;
    width: 100vw;
    scale: 2;
}

.Landing-div--button {
    position: absolute;
    bottom: -2.5em;
    display: flex;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.65em 1.25em;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
    color: #31B8B8;
    background-color: #FCFAFA;
    border-radius: 19px;
    min-width: 15em;
    width: fit-content;
    cursor: pointer;
}
.Landing-h5.title{
    font-family: 'League Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 4rem;
        color: #31B8B8;
}

@media screen and (min-width: 850px) {
    .Landing-div--foot {
        bottom: 0;
    }
    
    .Landing-svg {
        scale: 1;
    }
    
    .Landing-div--button {
        bottom: 2em;
    }
}