.Dragbar-div--background{
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 21rem;
    background-color: #E6E4E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height:2.5em;
    padding: .2em;
}
.Dragbar-p{
    color: #827979;
    font-weight: 700;
    font-size: 0.90rem;
}
.Dragbar-div--background.disabled{
    background-color: #e6e4e063;
}
.Dragbar-p.disabled{
    color: #82797948;
}
.Dragbar-div--horizontal{
    position: absolute;
    top:0;
    left:0;
    width: 21rem;
    height: 2.5em;
    padding: 0.4em;
    opacity: 1;
    border-radius: 20px;
}

.Dragbar-div--horizontal::-webkit-slider-thumb{
    -webkit-appearance: none;
    /* appearance: none; */
   color: black;
   width: 2rem;
   height: 2rem;
   border-radius: 50%;
   background-color: #31B8B8;
   background-image: url("../../public/dcha.png");
   background-size: 50%;
   background-repeat: no-repeat;
   background-position: center;
}
.Dragbar-div--horizontal::-ms-thumb{
    -ms-appearance: none;
    /* appearance: none; */
    color: black;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #31B8B8;
    background-image: url("../../public/dcha.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.Dragbar-div--horizontal::-moz-range-thumb{
    -moz-appearance: none;
    /* appearance: none; */
    color: black;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #31B8B8;
    background-image: url("../../public/dcha.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.Dragbar-div--horizontal:disabled::-webkit-slider-thumb{
    background-color: #31b8b88b;
}
.Dragbar-div--horizontal:disabled::-ms-thumb{
    background-color: #31b8b88b;
}
.Dragbar-div--horizontal:disabled::-moz-range-thumb{
    background-color: #31b8b88b;
}


