.AsideMenu-div {
    position: absolute;
    height: fit-content;
    z-index: 4;
}

.AsideMenu-div.isActive {
    width: 100%;
}

.AsideMenu-icon--Menu {
    width: fit-content;
    padding: 0.15em;
    margin-left: 0.6em;
    margin-top: 1.5em;
    font-size: 2.3rem;
    border-radius: 50%;
    background-color: #FCFAFA;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    color: #31B8B8;

}

.AsideMenu-icon--Menu.isActive {
    position: absolute;
    left: 0;
    width: fit-content;
}

.AsideMenu {
    position: absolute;
    top: 0;
    width: 0vw;
    height: 100%;
    overflow: hidden;
    transition: all 1.8s linear;
    background-color: #31B8B8;
    opacity: 0;
    z-index: 2;
}

.AsideMenu.isActive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;
    gap: 0.5em;
    width: fit-content;
    opacity: 1;
}

.AsideMenu-div--header {
    display: flex;
    width: 100%;
    margin-top: em;
    margin-bottom: 0.3em;
    justify-content: space-between;
    border-bottom: 1px solid rgba(49, 184, 184, 0.3);
    gap: 3em;
}
.AsideMenu-h1{
    width: 100%;
    font-family: 'League Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 3.5rem;
    text-align: center;
    line-height: 72px;
    color: #FCFAFA;
}
.AsideMenu-div-headericon {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-weight: 500;
    padding-left: 1em;
    margin-top: 1em;
    margin-left: 1em;
    font-size: 0.7rem;
    gap: 0.2em;
    color: #827979; 
}

.AsideMenu-div--Background {
    width: 0vw;
    transition: all 1s ease;
}

.AsideMenu-div--Background.isActive {
    display: absolute;
    width: 100%;
    height: 100vh;
    background-color: #0000006d;
    z-index: 1;
}

.AsideMenu-h2 {
    font-size: 1.5rem;
    margin: .5em;
    color: #FCFAFA;
    font-weight: 600;
    width: fit-content;
}
.AsideMenu-h2--booked{
    position: absolute;
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
    width: 100vw;
    top: 1em;
    margin: auto;
    z-index: -1;
}

.AsideMenu-div--link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba(1, 3, 3, 0.96);
    width: 100%;
    font-size: 1.2rem;
    padding: .4em;
    border-bottom: 1px solid rgba(49, 184, 184, 0.3);
    padding-left: 1em;

}
.AsideMenu-div--link.logout{
    margin-top: min(6em);
    margin-bottom: 2.3em;
    border-bottom: none;
}
.AsideMenu-h4 {
    font-size: 0.8rem;
    width: fit-content;
    font-weight: 600;
    margin-left: 1.2em;
    color: #0C2E30;
}

.AsideMenu-h4--header{
    font-size: 1.4rem;
    font-weight: 700;
    color: #0C2E30;
}

.AsideMenu-icon {
    width: fit-content;
    font-size: 1.4rem;
    color: #010303;
    margin-bottom: 0.1em;
}
.AsideMenu-icon.logout{
    margin-bottom: none;
    margin-left: 0.1em;
}

.AsideMenu-div-body{
    background-color: #FCFAFA;
    width: 100%;
    border-radius: 1.4em 1.4em 0 0 ;
}
.AsideMenu-btn{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0.5em 1.6em;
    background-color: #31B8B8;
    border-radius: 1.6em;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}