.Map{
    position:relative;
    width: 100vw;
    height: 100vh;
    width: 100svw;
    height: 100dvh;
}

.Map-div--sidebar{
    background-color: rgba(35, 55, 75, 0.9);
    width: fit-content;
    height: fit-content;
    color: #fff;
    z-index: 1;
    position: absolute;
    margin: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 4px;    
}

.Map-p{
    width:100%;
    height: 100%;
    font-size: 0.6em;
}
.Mark-div{
    width: fit-content;
    height: fit-content;
    background-color: #31B8B8;
    border-radius: 50%;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.54);
    padding: 0.3em;
}
.Marker-icon{    
    background-color: rgba(255, 255, 255, 0);
    width: 5rem;
    padding: 0.3em;
    transition: all 0.5s ease-out; 
    border-radius: 50%;
}
.Marker-icon2{
    width: 6rem;
}
.Marker-icon:hover{
    transform: scale(1.2);
    cursor: pointer;
}
.Map-icon--nav{
    position: absolute;
    bottom: 0;
    right:0;
    border-radius: 50%;
    width: fit-content;
    color: rgba(24, 24, 177, 0.823);
    font-size: 4rem;
    background-color: rgb(255, 255, 255);
    z-index: 3;
}

.Map-icon--navcenter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: fit-content;
    color: rgba(24, 24, 177, 0.823);
    font-size: 2rem;
    background-color: rgb(255, 255, 255);
    z-index: 3;
}